<template>
  <div class="not-found">
    <el-container>
      <el-header style="height:180px">
        <h1>404</h1>
      </el-header>
      <el-main>
        <h2>页面未找到</h2>
        <p>抱歉，您访问的页面不存在。</p>
      </el-main>
    </el-container>
  </div>
</template>

<script>
    export default {
        layout: 'empty',
        name: 'NotFound',
        
    }
</script>

<style scoped>
.not-found {
  text-align: center;
  padding: 50px;
}
 
.not-found h1 {
  font-size: 100px;
  margin-bottom: 20px;
}
 
.not-found h2 {
  font-size: 30px;
  margin-bottom: 20px;
}
 
.not-found p {
  font-size: 18px;
  margin-bottom: 40px;
}
</style>
