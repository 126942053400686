import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _62c0f53c = () => interopDefault(import('../pages/auction/index.vue' /* webpackChunkName: "pages/auction/index" */))
const _20fb500e = () => interopDefault(import('../pages/coalPriceAnno/index.vue' /* webpackChunkName: "pages/coalPriceAnno/index" */))
const _18141eca = () => interopDefault(import('../pages/codeurl/index.vue' /* webpackChunkName: "pages/codeurl/index" */))
const _77b7b1c5 = () => interopDefault(import('../pages/download/index.vue' /* webpackChunkName: "pages/download/index" */))
const _77dd45e0 = () => interopDefault(import('../pages/enterpriseQuery/index.vue' /* webpackChunkName: "pages/enterpriseQuery/index" */))
const _af349960 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _cfa906d6 = () => interopDefault(import('../pages/notice/index.vue' /* webpackChunkName: "pages/notice/index" */))
const _6dd3dfe0 = () => interopDefault(import('../pages/operation/index.vue' /* webpackChunkName: "pages/operation/index" */))
const _4f907220 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _4973f136 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _e49e7c52 = () => interopDefault(import('../pages/auction/detail.vue' /* webpackChunkName: "pages/auction/detail" */))
const _cdc8928c = () => interopDefault(import('../pages/coalPriceAnno/detail.vue' /* webpackChunkName: "pages/coalPriceAnno/detail" */))
const _044ff8fa = () => interopDefault(import('../pages/membermanagement/index11.vue' /* webpackChunkName: "pages/membermanagement/index11" */))
const _06b8737a = () => interopDefault(import('../pages/news/detail.vue' /* webpackChunkName: "pages/news/detail" */))
const _807fe87c = () => interopDefault(import('../pages/notice/cgdetail.vue' /* webpackChunkName: "pages/notice/cgdetail" */))
const _0597259e = () => interopDefault(import('../pages/notice/detail.vue' /* webpackChunkName: "pages/notice/detail" */))
const _a59bce68 = () => interopDefault(import('../pages/pcas/loading.vue' /* webpackChunkName: "pages/pcas/loading" */))
const _07839ee6 = () => interopDefault(import('../pages/register/form.vue' /* webpackChunkName: "pages/register/form" */))
const _7e9736b6 = () => interopDefault(import('../pages/auction/components/auction-notice.vue' /* webpackChunkName: "pages/auction/components/auction-notice" */))
const _3c32010e = () => interopDefault(import('../pages/auction/components/pic-zoom.vue' /* webpackChunkName: "pages/auction/components/pic-zoom" */))
const _509cff22 = () => interopDefault(import('../pages/auction/components/pic-zoom-list.vue' /* webpackChunkName: "pages/auction/components/pic-zoom-list" */))
const _0f8a877b = () => interopDefault(import('../pages/auction/mixins/time.js' /* webpackChunkName: "pages/auction/mixins/time" */))
const _e625f802 = () => interopDefault(import('../pages/auction/mixins/websocket.js' /* webpackChunkName: "pages/auction/mixins/websocket" */))
const _768e2be8 = () => interopDefault(import('../pages/function/expert/form.vue' /* webpackChunkName: "pages/function/expert/form" */))
const _411923eb = () => interopDefault(import('../pages/operation/component/process.vue' /* webpackChunkName: "pages/operation/component/process" */))
const _b1e3e860 = () => interopDefault(import('../pages/register/component/dialog-clause.vue' /* webpackChunkName: "pages/register/component/dialog-clause" */))
const _2e95cff4 = () => interopDefault(import('../pages/function/expert/components/upload-idcard-face.vue' /* webpackChunkName: "pages/function/expert/components/upload-idcard-face" */))
const _ee58eff0 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1260da4a = () => interopDefault(import('../pages/index/components/login/index.vue' /* webpackChunkName: "pages/index/components/login/index" */))
const _445d3300 = () => interopDefault(import('../pages/index/components/right-bar.vue' /* webpackChunkName: "pages/index/components/right-bar" */))
const _7ae0388e = () => interopDefault(import('../pages/index/components/user-info.vue' /* webpackChunkName: "pages/index/components/user-info" */))
const _37e711b4 = () => interopDefault(import('../pages/index/sections/banner.vue' /* webpackChunkName: "pages/index/sections/banner" */))
const _361df171 = () => interopDefault(import('../pages/index/sections/bidding.vue' /* webpackChunkName: "pages/index/sections/bidding" */))
const _057d87b0 = () => interopDefault(import('../pages/index/sections/info.vue' /* webpackChunkName: "pages/index/sections/info" */))
const _0457ce2f = () => interopDefault(import('../pages/index/sections/links.vue' /* webpackChunkName: "pages/index/sections/links" */))
const _0b16b575 = () => interopDefault(import('../pages/index/sections/operation-center.vue' /* webpackChunkName: "pages/index/sections/operation-center" */))
const _5705d365 = () => interopDefault(import('../pages/index/sections/qualification.vue' /* webpackChunkName: "pages/index/sections/qualification" */))
const _bdb95d74 = () => interopDefault(import('../pages/index/sections/supplier.vue' /* webpackChunkName: "pages/index/sections/supplier" */))
const _324e1f31 = () => interopDefault(import('../pages/index/sections/video-bit.vue' /* webpackChunkName: "pages/index/sections/video-bit" */))
const _3fa4eaec = () => interopDefault(import('../pages/index/components/ad/bullet-frame.vue' /* webpackChunkName: "pages/index/components/ad/bullet-frame" */))
const _e4007184 = () => interopDefault(import('../pages/index/components/ad/float-ad.vue' /* webpackChunkName: "pages/index/components/ad/float-ad" */))
const _4c10be32 = () => interopDefault(import('../pages/index/components/ad/suspension.vue' /* webpackChunkName: "pages/index/components/ad/suspension" */))
const _7ec4c2a5 = () => interopDefault(import('../pages/index/components/ad/top-ad.vue' /* webpackChunkName: "pages/index/components/ad/top-ad" */))
const _73cdf5c1 = () => interopDefault(import('../pages/index/components/login/account-login.vue' /* webpackChunkName: "pages/index/components/login/account-login" */))
const _da49da1c = () => interopDefault(import('../pages/index/components/login/ca-login.vue' /* webpackChunkName: "pages/index/components/login/ca-login" */))
const _7449e969 = () => interopDefault(import('../pages/index/components/login/cas-login.vue' /* webpackChunkName: "pages/index/components/login/cas-login" */))
const _1e2e84a6 = () => interopDefault(import('../pages/index/components/login/forgot-form.vue' /* webpackChunkName: "pages/index/components/login/forgot-form" */))
const _8c10787c = () => interopDefault(import('../pages/index/components/login/phone-login.vue' /* webpackChunkName: "pages/index/components/login/phone-login" */))
const _06b94217 = () => interopDefault(import('../pages/index/components/login/update-password.vue' /* webpackChunkName: "pages/index/components/login/update-password" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/auction",
    component: _62c0f53c,
    alias: "/auction/index.html",
    name: "auction"
  }, {
    path: "/coalPriceAnno",
    component: _20fb500e,
    alias: "/coalPriceAnno/index.html",
    name: "coalPriceAnno"
  }, {
    path: "/codeurl",
    component: _18141eca,
    alias: "/codeurl/index.html",
    name: "codeurl"
  }, {
    path: "/download",
    component: _77b7b1c5,
    alias: "/download/index.html",
    name: "download"
  }, {
    path: "/enterpriseQuery",
    component: _77dd45e0,
    alias: "/enterpriseQuery/index.html",
    name: "enterpriseQuery"
  }, {
    path: "/news",
    component: _af349960,
    alias: "/news/index.html",
    name: "news"
  }, {
    path: "/notice",
    component: _cfa906d6,
    alias: "/notice/index.html",
    name: "notice"
  }, {
    path: "/operation",
    component: _6dd3dfe0,
    alias: "/operation/index.html",
    name: "operation"
  }, {
    path: "/register",
    component: _4f907220,
    alias: "/register/index.html",
    name: "register"
  }, {
    path: "/search",
    component: _4973f136,
    alias: "/search/index.html",
    name: "search"
  }, {
    path: "/auction/detail",
    component: _e49e7c52,
    alias: "/auction/detail/index.html",
    name: "auction-detail"
  }, {
    path: "/coalPriceAnno/detail",
    component: _cdc8928c,
    alias: "/coalPriceAnno/detail/index.html",
    name: "coalPriceAnno-detail"
  }, {
    path: "/membermanagement/index11",
    component: _044ff8fa,
    alias: "/membermanagement/index11/index.html",
    name: "membermanagement-index11"
  }, {
    path: "/news/detail",
    component: _06b8737a,
    alias: "/news/detail/index.html",
    name: "news-detail"
  }, {
    path: "/notice/cgdetail",
    component: _807fe87c,
    alias: "/notice/cgdetail/index.html",
    name: "notice-cgdetail"
  }, {
    path: "/notice/detail",
    component: _0597259e,
    alias: "/notice/detail/index.html",
    name: "notice-detail"
  }, {
    path: "/pcas/loading",
    component: _a59bce68,
    alias: "/pcas/loading/index.html",
    name: "pcas-loading"
  }, {
    path: "/register/form",
    component: _07839ee6,
    alias: "/register/form/index.html",
    name: "register-form"
  }, {
    path: "/auction/components/auction-notice",
    component: _7e9736b6,
    alias: "/auction/components/auction-notice/index.html",
    name: "auction-components-auction-notice"
  }, {
    path: "/auction/components/pic-zoom",
    component: _3c32010e,
    alias: "/auction/components/pic-zoom/index.html",
    name: "auction-components-pic-zoom"
  }, {
    path: "/auction/components/pic-zoom-list",
    component: _509cff22,
    alias: "/auction/components/pic-zoom-list/index.html",
    name: "auction-components-pic-zoom-list"
  }, {
    path: "/auction/mixins/time",
    component: _0f8a877b,
    alias: "/auction/mixins/time/index.html",
    name: "auction-mixins-time"
  }, {
    path: "/auction/mixins/websocket",
    component: _e625f802,
    alias: "/auction/mixins/websocket/index.html",
    name: "auction-mixins-websocket"
  }, {
    path: "/function/expert/form",
    component: _768e2be8,
    alias: "/function/expert/form/index.html",
    name: "function-expert-form"
  }, {
    path: "/operation/component/process",
    component: _411923eb,
    alias: "/operation/component/process/index.html",
    name: "operation-component-process"
  }, {
    path: "/register/component/dialog-clause",
    component: _b1e3e860,
    alias: "/register/component/dialog-clause/index.html",
    name: "register-component-dialog-clause"
  }, {
    path: "/function/expert/components/upload-idcard-face",
    component: _2e95cff4,
    alias: "/function/expert/components/upload-idcard-face/index.html",
    name: "function-expert-components-upload-idcard-face"
  }, {
    path: "/",
    component: _ee58eff0,
    alias: "/index.html",
    name: "index",
    children: [{
      path: "components/login",
      component: _1260da4a,
      name: "index-components-login"
    }, {
      path: "components/right-bar",
      component: _445d3300,
      name: "index-components-right-bar"
    }, {
      path: "components/user-info",
      component: _7ae0388e,
      name: "index-components-user-info"
    }, {
      path: "sections/banner",
      component: _37e711b4,
      name: "index-sections-banner"
    }, {
      path: "sections/bidding",
      component: _361df171,
      name: "index-sections-bidding"
    }, {
      path: "sections/info",
      component: _057d87b0,
      name: "index-sections-info"
    }, {
      path: "sections/links",
      component: _0457ce2f,
      name: "index-sections-links"
    }, {
      path: "sections/operation-center",
      component: _0b16b575,
      name: "index-sections-operation-center"
    }, {
      path: "sections/qualification",
      component: _5705d365,
      name: "index-sections-qualification"
    }, {
      path: "sections/supplier",
      component: _bdb95d74,
      name: "index-sections-supplier"
    }, {
      path: "sections/video-bit",
      component: _324e1f31,
      name: "index-sections-video-bit"
    }, {
      path: "components/ad/bullet-frame",
      component: _3fa4eaec,
      name: "index-components-ad-bullet-frame"
    }, {
      path: "components/ad/float-ad",
      component: _e4007184,
      name: "index-components-ad-float-ad"
    }, {
      path: "components/ad/suspension",
      component: _4c10be32,
      name: "index-components-ad-suspension"
    }, {
      path: "components/ad/top-ad",
      component: _7ec4c2a5,
      name: "index-components-ad-top-ad"
    }, {
      path: "components/login/account-login",
      component: _73cdf5c1,
      name: "index-components-login-account-login"
    }, {
      path: "components/login/ca-login",
      component: _da49da1c,
      name: "index-components-login-ca-login"
    }, {
      path: "components/login/cas-login",
      component: _7449e969,
      name: "index-components-login-cas-login"
    }, {
      path: "components/login/forgot-form",
      component: _1e2e84a6,
      name: "index-components-login-forgot-form"
    }, {
      path: "components/login/phone-login",
      component: _8c10787c,
      name: "index-components-login-phone-login"
    }, {
      path: "components/login/update-password",
      component: _06b94217,
      name: "index-components-login-update-password"
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
